// Our main CSS
import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view
// const autoplaying_videos = document.querySelectorAll('video[playsinline]')

// if (autoplaying_videos && autoplaying_videos.length) {
//   import('./modules/inline-videos').then((module) => {
//     module.default(autoplaying_videos)
//   })
// }

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/* button set width equal to height */

document.addEventListener('DOMContentLoaded', () => {
  function setWidthEqualHeight(element) {
    const height = element.offsetHeight
    element.style.width = `${height}px`
  }

  window.onload = function () {
    const elements = document.querySelectorAll('.arrow')
    elements.forEach((element) => {
      setWidthEqualHeight(element)
    })
  }

  /* slider */

  const images = document.querySelectorAll('.slide-image')
  const links = document.querySelectorAll('.link')
  const progressBars = document.querySelectorAll('.progress-bar')
  let currentSlide = 0
  const slideInterval = 5000

  function showSlide(index) {
    images.forEach((image, i) => {
      image.classList.toggle('active', i === index)
    })

    links.forEach((link, i) => {
      link.classList.toggle('active', i === index)
    })

    progressBars.forEach((bar, i) => {
      bar.style.width = '0%'
      if (i === index) {
        animateProgressBar(bar)
      }
    })
  }

  function animateProgressBar(bar) {
    let width = 0
    const interval = setInterval(() => {
      width += 1
      bar.style.width = width + '%'
      if (width >= 100) {
        clearInterval(interval)
      }
    }, slideInterval / 100)
  }

  function nextSlide() {
    currentSlide = (currentSlide + 1) % images.length
    showSlide(currentSlide)
  }

  setInterval(nextSlide, slideInterval)
  showSlide(currentSlide)

  /* menu */

  let menu = document.querySelector('.js-menu')
  let subMenuLinks = document.querySelectorAll('.menu-item-has-children > a')
  let hamburger = document.querySelector('.js-mobile-menu-btn')
  let isHamburgerMenuOpen = false
  let menuWrapper = document.querySelector('.menu-wrapper')
  let menuSvg = document.querySelector('.js-menu-svg')
  let target = document.querySelector('.target')
  let navLinks = document.querySelectorAll('.menu-wrapper > div > ul > li > a')
  let navMenu = document.querySelector('.menu')
  let currentItem = document.querySelector('.menu .current-menu-item > a')

  // Set initial hover target color
  if (menu.classList.contains('menu-white')) {
    target.classList.add('bg-white')
    target.classList.remove('bg-black')
  }

  function handleScroll() {
    if (window.scrollY > 0) {
      menu.classList.add('bg-white')
      menu.classList.add('shadow-custom')
      menu.classList.remove('text-white')
      menu.classList.add('text-black')
      menuSvg.classList.add('text-black')
      if (menu.classList.contains('menu-white')) {
        target.classList.remove('bg-white')
        target.classList.add('bg-black')
        currentItem.classList.remove('before:bg-white')
        currentItem.classList.add('before:bg-black')
      }
    } else {
      let isSubmenuVisible = !!document.querySelector('.submenu-visible')
      if (!isSubmenuVisible && !isHamburgerMenuOpen) {
        menu.classList.remove('bg-white')
        menu.classList.remove('shadow-custom')
        if (menu.classList.contains('menu-white')) {
          menu.classList.add('text-white')
          menu.classList.remove('text-black')
          menu.classList.remove('shadow-custom')
          menuSvg.classList.remove('text-black')
          target.classList.add('bg-white')
          target.classList.remove('bg-black')
          currentItem.classList.add('before:bg-white')
          currentItem.classList.remove('before:bg-black')
        }
      }
    }
  }

  window.addEventListener('scroll', handleScroll)

  function handleSubmenuClick(event) {
    event.preventDefault()
    event.stopPropagation()

    this.classList.toggle('submenu-visible')

    let submenu = event.target.nextElementSibling
    if (submenu && submenu.classList.contains('sub-menu')) {
      submenu.classList.toggle('submenu-visible')
      menu.classList.add('bg-white', 'text-black')
      menu.classList.remove('text-white')
      target.classList.remove('bg-white')
      target.classList.add('bg-black')
      currentItem.classList.remove('before:bg-white')
      currentItem.classList.add('before:bg-black')
    }
  }

  subMenuLinks.forEach(function (link) {
    link.addEventListener('click', handleSubmenuClick)
  })

  document.addEventListener('click', function () {
    let openSubmenus = document.querySelectorAll('.submenu-visible')
    openSubmenus.forEach(function (submenu) {
      submenu.classList.remove('submenu-visible')
    })

    if (window.scrollY === 0) {
      let isSubmenuVisible = !!document.querySelector('.submenu-visible')
      if (!isSubmenuVisible && !isHamburgerMenuOpen) {
        menu.classList.remove('bg-white', 'text-black')
        if (menu.classList.contains('menu-white')) {
          menu.classList.add('text-white')
        }
      }
    }
  })

  document.querySelectorAll('.sub-menu').forEach(function (submenu) {
    submenu.addEventListener('click', function (event) {
      event.stopPropagation()
    })
  })

  hamburger.addEventListener('click', function () {
    this.classList.toggle('open')

    isHamburgerMenuOpen = !isHamburgerMenuOpen
    menuWrapper.classList.toggle('open')
    if (isHamburgerMenuOpen) {
      menu.classList.add('bg-white', 'text-black')
      menu.classList.remove('text-white')
    } else {
      if (window.scrollY === 0) {
        let isSubmenuVisible = !!document.querySelector('.submenu-visible')
        if (!isSubmenuVisible) {
          menu.classList.remove('bg-white', 'text-black')
          if (menu.classList.contains('menu-white')) {
            menu.classList.add('text-white')
          }
        }
      }
    }
  })

  /* nav hover */

  function positionTarget(element) {
    const rect = element.getBoundingClientRect()
    const menuRect = document.querySelector('.menu').getBoundingClientRect()

    target.style.width = `${rect.width}px`
    target.style.left = `${rect.left - menuRect.left}px`
    target.style.opacity = '1'
    target.style.transform = 'none'
  }

  navLinks.forEach((link) => {
    link.addEventListener('mouseenter', function () {
      positionTarget(this)
    })
  })

  function hideTarget() {
    target.style.opacity = '0' // Hide target
  }

  navMenu.addEventListener('mouseleave', () => {
    hideTarget()
  })

  window.addEventListener('resize', () => {
    const currentLink = document.querySelector('.menu a.current')
    if (currentLink) {
      positionTarget(currentLink)
    }
  })

  /* accordion */

  const accordionItems = document.querySelectorAll('.js-accordion-item')

  accordionItems.forEach((item) => {
    const title = item.querySelector('.js-accordion-title')

    title.addEventListener('click', function () {
      item.classList.toggle('active')

      const content = item.querySelector('.js-accordion-content')
      if (item.classList.contains('active')) {
        content.style.maxHeight = content.scrollHeight + 'px'
      } else {
        content.style.maxHeight = 0
      }
    })
  })
})
